import { OrganizationDetailsPrograms } from '../components/Organization/Details/Channel';

export function OrganizationProgramsRoute() {
  return <OrganizationDetailsPrograms />;
}

export const Component = OrganizationProgramsRoute;

export function clientLoader() {
  return null;
}
